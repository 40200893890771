import React, { useEffect, useContext } from 'react'
import Layout from '../components/Global/Layout'
import Loader from '../components/Global/Loader'
import Homepage from '../components/Homepage'
import { graphql, navigate } from 'gatsby'
import { GlobalStateContext } from '../context/GlobalContextProvider'

export const query = graphql`
  {
    allContentfulHomePage {
      edges {
        node {
          title
          bodyText0
          firstCta {
            internal {
              content
            }
          }
          headingText0
          buttonText2 {
            internal {
              content
            }
          }
          headingText1
          workCardsTitle
          workCardsBody {
            internal {
              content
            }
          }
          workCardsIcon {
            file {
              url
            }
          }
          buttonText3 {
            internal {
              content
            }
          }
          headingText2
          headingText3
          trademarkDifferences {
            internal {
              content
            }
          }
          heirlumeAdvantageTable {
            internal {
              content
            }
          }
          tableLogo {
            file {
              url
            }
          }
          clearbancCardText {
            internal {
              content
            }
          }
          clearcoLogo {
            file {
              url
            }
          }
          quotesText {
            internal {
              content
            }
          }
          quotesImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          headingText4
          partnersNetworkLogo {
            file {
              url
              fileName
            }
          }
          registerCardText {
            internal {
              content
            }
          }
          headingText5
          faqText {
            internal {
              content
            }
          }
          searchCardText {
            internal {
              content
            }
          }
          searchCardImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          headingText6
          featuredLogos {
            file {
              url
              fileName
            }
          }
          headingText7
          qualifiedLogos {
            file {
              url
              fileName
            }
          }
          headingText8
          headingText9
          servicesCardTitle
          servicesCardBody
          servicesCardImage {
            file {
              url
            }
          }
          servicesCardButton {
            internal {
              content
            }
          }
          protectCardText {
            internal {
              content
            }
          }
          seoContent {
            internal {
              content
            }
          }
          node_locale
        }
      }
    }
  }
`

const HomepageView = (props) => {
  const { allContentfulHomePage } = props.data
  const state = useContext(GlobalStateContext)

  useEffect(() => {
    if (!state.loading) {
      const { locale } = state
      navigate(`/${locale.toLowerCase()}/`)
    }
  }, [state.loading])

  const homeData = allContentfulHomePage?.edges.filter(
    (node) => node.node.node_locale === state.locale || 'en-CA'
  )[0].node

  // return (
  //   <Layout locale={state.locale}>
  //     <Loader loading={state.loading} />
  //   </Layout>
  // )
  return (
    <Layout
      locale={state.locale}
      title={JSON.parse(homeData?.seoContent.internal.content).title}
      desc={JSON.parse(homeData?.seoContent.internal.content).desc}
      url=''
    >
      <Homepage locale={state.locale} homeData={homeData} />
    </Layout>
  )
}

export default HomepageView
